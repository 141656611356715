import React from 'react';
import Helmet from 'react-helmet';

const defaultTitle = 'Publisher Workspace';

interface Props {
  title?: string;
}

const PageTitle = ({ title }: Props) => (
  <Helmet>
    <title>
      {title ? title : defaultTitle} | Stories Service
    </title>
  </Helmet>
);

export default PageTitle;
