import React from "react";
import { Button, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { FiHeart } from "react-icons/all";

const useStyles = makeStyles(theme => ({
  footer: {
    background: theme.palette.primary.main,
    marginTop: theme.spacing(10),
  },
  button: {
    color: theme.palette.background.default,
  },
  container: {
    width: "100%",
    padding: theme.spacing(5),
    color: theme.palette.background.default,
  },
  links: {
  },
  divider: {
    background: theme.palette.background.default,
    opacity: .5,
    height: theme.typography.h4.fontSize,
    margin: theme.spacing(0, 2),
  },
  light:{
    opacity: .5
  }

}));


export default function Footer(props) {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container className={classes.container} justifyContent="center" spacing={2}>
        <Grid item md={8}>
          <Grid container className={classes.links} justifyContent="center">
            <Button className={classes.button} href='/api'>
              API Docs
            </Button>
            <Divider className={classes.divider} orientation="vertical" />
            <Button className={classes.button} href='/publisher/legal'>
              Legal
            </Button>
            <Divider className={classes.divider} orientation="vertical" />
            <Button color="secondary" href='http://sciencestories.io' target="_blank">
              Science Stories
            </Button>
            <Divider className={classes.divider} orientation="vertical" />
            <Button className={classes.button} href='/publisher/join'>
              Register
            </Button>
            <Divider className={classes.divider} orientation="vertical" />
            <Button className={classes.button} href='/publisher/login'>
              Login
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={10} className={classes.light}>
          <Typography variant="subtitle2">
            &copy; {1900 + new Date().getYear()} , made with{"  "}
            <FiHeart />{"  "} by{" "}
            <Button color="secondary" href="http://sciencestories.io" target="_blank">
              The ScienceStories.io Team
            </Button>{" "} for a better web.
          </Typography>
        </Grid>
        <Grid item xs={10} className={classes.light}>
          <Typography variant="caption">
          <Button href="http://seals-nutt.com" target="_blank">
            A SEALS-NUTT APP
          </Button>
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

Footer.propTypes = {

};
