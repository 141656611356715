import { AppState } from '../types';
import * as actionTypes from './actions/types';

export interface ActionDispatch extends Partial<AppState>{
  type: keyof typeof actionTypes;
}

const initialState: AppState = {
  admin: undefined,
  authenticated: false, // Check if the user is logged in
  canAccessContentManager: false,
  collection: undefined,
  currentProject: null,
  error: null,
  expiresIn: undefined,
  isAdmin: false,
  loading: false,
  msg: '',
  pageAuthorized: true, // Check if a page can be viewed
  plans: null,
  projectUsage: null,
  projects: null,
  publisherIsPremium: false,
  settings: undefined,
  token: null,
  user: null,
};

const updateObject = (oldObject: object, updatedProperties: object) => (
  { ...oldObject, ...updatedProperties }
);

const reducer = (state = initialState, action: ActionDispatch) => {
  switch (action.type) {
    case actionTypes.SIGNUP_START:
      return updateObject(state, { error: null, loading: true, user: null });
    case actionTypes.SIGNUP_SUCCESS:
      return updateObject(state, {
        authenticated: true,
        error: null,
        expiresIn: action.expiresIn,
        loading: false,
        token: action.token,
        user: action.user,
      });
    case actionTypes.SIGNUP_FAIL:
      return updateObject(state, {
        error: action.error,
        loading: false,
        msg: 'Signup Failed',
        user: null,
      });
    case actionTypes.SIGNIN_START:
      return updateObject(state, {
        error: null,
        loading: true,
        token: null,
        user: null,
      });
    case actionTypes.SIGNIN_SUCCESS:
      return updateObject(state, {
        authenticated: true,
        error: null,
        loading: false,
        token: action.token,
        user: action.user,
      });
    case actionTypes.SIGNIN_FAIL:
      return updateObject(state, {
        error: action.error,
        loading: false,
        msg: 'Login Failed',
        user: null,
      });
    case actionTypes.PAGE_AUTHORIZATION_FAILED:
      return updateObject(state, { pageAuthorized: false, loading: false, msg: action.msg });
    case actionTypes.AUTHENTICATION_FAILED:
      return updateObject(state, {
        authenticated: false,
        loading: false,
        token: null,
        user: null,
      });
    case actionTypes.SIGNOUT_START:
      return updateObject(state, { error: null, loading: true });
    case actionTypes.SIGNOUT_SUCCESS:
      return initialState;
    case actionTypes.GET_CURRENT_PUBLISHER:
      return updateObject(state,
        {
          admin: action.admin,
          authenticated: true,
          canAccessContentManager: action.canAccessContentManager,
          error: null,
          loading: false,
          isAdmin: action.isAdmin,
          pageAuthorized: true,
          projects: action.projects,
          publisherIsPremium: action.publisherIsPremium,
          user: action.user,
        });
    case actionTypes.UPDATE_PASSWORD:
      return updateObject(state, { authenticated: true, pageAuthorized: true, loading: false });
    case actionTypes.UPDATE_PUBLISHER:
      return updateObject(state, { loading: false });
    case actionTypes.CREATE_COLLECTION:
      return updateObject(state, {
        collection: action.collection,
        error: action.error,
        loading: false,
        msg: action.msg,
      });
    case actionTypes.GET_COLLECTION:
      return updateObject(state, {
        collection: action.collection,
        error: action.error,
        loading: action.loading,
        msg: action.msg,
      });
    case actionTypes.CREATE_PROJECT:
      return updateObject(state, {
        currentProject: action.currentProject,
        error: action.error,
        msg: action.msg,
        loading: false,
      });
    case actionTypes.GET_PROJECT:
      return updateObject(state, {
        currentProject: action.currentProject,
        error: action.error,
        msg: action.msg,
        loading: false,
      });
    case actionTypes.ADD_PUBLISHER_TO_PROJECT:
      return updateObject(state, {
        currentProject: action.currentProject,
        error: action.error,
        msg: action.msg,
        loading: false,
      });
    case actionTypes.GET_PROJECT_USAGE:
      return updateObject(state, {
        projectUsage: action.projectUsage,
        error: action.error,
        msg: action.msg,
        loading: false,
      });
    case actionTypes.GET_PLANS:
      return updateObject(state, { plans: action.plans, loading: false });
    case actionTypes.GET_PUBLISHER_WORKSPACE_SETTINGS:
      return updateObject(state, { settings: action.settings, loading: false });
    default:
      return state;
  }
};

export default reducer;
