import { SnackbarProvider } from 'notistack';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';

// TODO: Add TS Support for Navbar
// @ts-ignore
import NavbarContainer from '../Components/Container/Navbar';
import PageTitle from '../Components/Container/Page/Title';
import routes from '../routes';
import './App.css';
import useStyles from './useStyles';

// Note: URL Base pattern need to be the same as the path of the django app
const App = () => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const fullScreen = pathname.includes('/embed/') || pathname.includes('/sandbox');
  return (
    <SnackbarProvider
      className={classes.snackbarProvider}
      maxSnack={3}
    >
      <div className="App">
        <PageTitle />
        { fullScreen ? routes : (
          <NavbarContainer>
            {routes}
          </NavbarContainer>
        )}
      </div>
    </SnackbarProvider>
  );
};

export default withRouter(connect()(App));
