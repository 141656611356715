const theme = {
  palette: {
    primary: {
      main: '#00838f',
    },
    secondary: {
      main: '#9ab47d',
    },
  },
};

export default theme;
