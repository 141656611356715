import React, { Component } from "react";
import { Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import { FaUserAstronaut } from 'react-icons/all';

export default class UserAvatar extends Component {

  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
  };

  render(){
    const { className, user } = this.props;

    return (
      <Avatar className={className}>
        {(user && user.initials) ? (
          `${user.initials.charAt(0)}${user.initials.slice(1).toLowerCase()}`
        ) : (
          <FaUserAstronaut />
        )}
      </Avatar>
    )
  }
}
