import api from '../../client';
import * as actionTypes from './types';

const setToken = (token, next) => {
  localStorage.setItem('token', token);
  return next(token);
};

const setUserState = (token, data, dispatch, next) => {
  setToken(token, (token) => {
    localStorage.setItem('user', JSON.stringify(data));
    dispatch(next(token, data));
  });
};

export const pageNotAccessible = (msg, dispatch) => {
 return dispatch({
    type: actionTypes.PAGE_AUTHORIZATION_FAILED,
    msg: msg,
  });
};

// ======================================== SIGNUP ======================================== //

export const signUpStart = () => {
  return {
    type: actionTypes.SIGNUP_START
  };
};

export const signUpSuccess = (token, user) => {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    token: token,
    user: user,
  };
};

export const signUpFail = error => {
  return {
    type: actionTypes.SIGNUP_FAIL,
    error: JSON.stringify(error.data)
  };
};

export const signUp = (data, callback, errCallback) => {
  const { email, password, confirm, username, } = data;
  return dispatch => {
    dispatch(signUpStart());
    api.post('auth/registration', {email: email, password1: password, password2: confirm, username: username})
      .then(({ key }) => {
        setToken(key, (token) => {
           // Then get user data
          api.get('user/current')
          .then(response => {
            setUserState(token, response, dispatch, signUpSuccess);
            if (callback) callback(response);
          })
          .catch(err => {
            dispatch(signUpFail(api.parseError(err)));
            errCallback && errCallback(api.parseError(err));
          });
        });
      })
      .catch(err => {
        console.error("error", err);
        dispatch(signUpFail(api.parseError(err)));
        errCallback && errCallback(api.parseError(err));
      });
  };
};

// ======================================== SIGNIN ======================================== //
export const signInStart = () => {
  return {
    type: actionTypes.SIGNIN_START
  };
};

export const signInSuccess = (token, user) => {
  return {
    type: actionTypes.SIGNIN_SUCCESS,
    token: token,
    user: user,
  };
};

export const signInFail = error => {
  clearAuthentication();
  return {
    type: actionTypes.SIGNIN_FAIL,
    error: JSON.stringify(error.data)
  };
};

export const signIn = (username, password, callback, errCallback) => {
  return dispatch => {
    dispatch(signInStart());
    // Login to get token
    api.post('auth/login', {username: username, password: password})
      .then(({ key }) => {
        setToken(key, (token) => {
           // Then get user data
          api.get('user/current')
          .then(response => {
            setUserState(token, response, dispatch, signInSuccess);
            if (callback) callback(response);
          })
          .catch(err => {
            dispatch(signInFail(api.parseError(err)));
            errCallback && errCallback(err.data);
          });
        });
      })
      .catch(err => {
        dispatch(signInFail(api.parseError(err)));
        errCallback && errCallback(err.data);
      });
  };
};

// ======================================== SIGNOUT ======================================== //

export const signOutStart = () => {
  return {
    type: actionTypes.SIGNOUT_START,
    loading: true,
  };
};

export const signOutSuccess = () => {
  return {
    type: actionTypes.SIGNOUT_SUCCESS,
    msg: "Signed Out"
  };
};

export const signOut = (callback) => {
  localStorage.clear();
  return dispatch => {
    dispatch(signOutStart());
    api.post('auth/logout')
      .then(() => {
        dispatch(signOutSuccess());
        if (callback) return callback();
      })
      .catch(err => console.log(err));
  };
};

export const clearAuthentication = (msg) => (dispatch) => {
  // eslint-disable-next-line no-console
  console.log('CLEARING STORAGE');
  localStorage.clear();
  return dispatch({
    authenticated: false,
    msg,
    projects: null,
    type: actionTypes.AUTHENTICATION_FAILED,
  });
};

// ======================================== PUBLISHER ======================================== //

export const getCurrentPublisher = (callback) => (dispatch) => api.get('publisher/current')
  .then((data) => {
    const { admin } = data;
    dispatch({
      admin,
      canAccessContentManager: data.can_access_content_manager,
      isAdmin: !!admin,
      projects: data.projects,
      publisherIsPremium: data.is_premium,
      type: actionTypes.GET_CURRENT_PUBLISHER,
      user: data.user,
    });
    if (callback) {
      callback(data);
    }
  })
  .catch((err) => {
    console.error(err);
    return dispatch(clearAuthentication('Error Fetching Publisher info'));
  });

export const updateUser = (data, callback, errCallback) => dispatch => {
  return api.put(`auth/user`, data)
    .then(dispatch(getCurrentPublisher((publisher) => {
        callback && callback(publisher);
        return {
          type: actionTypes.UPDATE_PUBLISHER,
        }
    })))
    .catch(err => {
      console.error("error", err);
      errCallback && errCallback(api.parseError(err));
    });
};

export const updatePassword = (data, callback, errCallback) => dispatch => {
  return api.post(`auth/password/change`, data)
    .then(res => {
        dispatch({
          type: actionTypes.UPDATE_PASSWORD
        });
        callback && callback(res);

    })
    .catch(err => {
      console.error("error", err);
      errCallback && errCallback(api.parseError(err));
    });
};



// ======================================== PROJECT ======================================== //

export const createProject = (data, callback) => dispatch => {
  return api.post('project', data)
    .then(data => {
      dispatch({
        type: actionTypes.CREATE_PROJECT,
        currentProject: data,
      });
      dispatch(getCurrentPublisher());
      if (callback){
        return callback(data);
      }
    })
    .catch(err => {
      console.error(err);
      return {
        type: actionTypes.CREATE_PROJECT,
        error: true,
        msg: "Error Creating Project"
      };
    });
};


export const getProject = (projectId, callback) => dispatch => {
  return api.get(`project/${projectId}`)
    .then(data => {
      dispatch({
        type: actionTypes.GET_PROJECT,
        currentProject: data,
        error: false,
        msg: null,
        loading: false,
      });
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return pageNotAccessible("Error loading this project", dispatch);
    });
};


export const addPublisherToProject = (projectId, data, callback) => dispatch => {
  return api.post(`project/${projectId}/add_publisher`, data)
    .then(data => {
      dispatch({
        type: actionTypes.ADD_PUBLISHER_TO_PROJECT,
        currentProject: data,
      });
      callback && callback(data);
      return dispatch(getCurrentPublisher());
    })
    .catch(err => {
      console.error(err);
      return {
        type: actionTypes.ADD_PUBLISHER_TO_PROJECT,
        error: true,
        msg: "Error Adding Collaborator to this Project."
      };
    });
};

export const getProjectUsage = (projectId, callback) => dispatch => {
  return api.get(`project/${projectId}/usage`)
    .then(data => {
      dispatch({
        type: actionTypes.GET_PROJECT_USAGE,
        projectUsage: data,
      });
      callback && callback(data);
    })
    .catch(err => {
      console.error(err);
      return {
        type: actionTypes.GET_PROJECT_USAGE,
        error: true,
        msg: "Error Adding Collaborator to this Project."
      };
    });
};

export const updatePrimaryPublisher = (projectId, data, callback) => dispatch => {
  return api.post(`project/${projectId}/primary_publisher`, data)
    .then(data => {
      dispatch({
        type: actionTypes.GET_PROJECT,
        currentProject: data,
      });
      callback && callback(data);
    })
    .catch(err => {
      console.error(err);
      return {
        type: actionTypes.GET_PROJECT,
        error: true,
        msg: "Error Setting Primary Publisher to this Project."
      };
    });
};

export const resetProjectAPIKey = (projectId, callback) => dispatch => {
  return api.post(`project/${projectId}/reset_api_key`)
    .then(data => {
      dispatch({
        type: actionTypes.GET_PROJECT,
        currentProject: data,
        error: false,
        msg: null,
        loading: false,
      });
      callback && callback(data);
    })
    .catch(err => {
      console.error(err);
      return {
        type: actionTypes.GET_PROJECT,
        error: true,
        msg: "Error Resetting Project API Key."
      };
    });
};

// ======================================== COLLECTION ======================================== //

export const createCollection = (data, callback) => dispatch => {
  return api.post('collection', data)
    .then(data => {
      dispatch({
        type: actionTypes.CREATE_COLLECTION,
        collection: data,
      });
      dispatch(getCurrentPublisher());
      if (callback){
        return callback(data);
      }
    })
    .catch(err => {
      console.error(err);
      return {
        type: actionTypes.CREATE_COLLECTION,
        error: true,
        msg: "Error Creating Collection"
      };
    });
};


export const getCollection = (collectionId, callback) => dispatch => {
  return api.get(`collection/${collectionId}`)
    .then(data => {
      dispatch({
        type: actionTypes.GET_COLLECTION,
        collection: data,
        error: false,
        msg: null,
        loading: false,
      });
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return pageNotAccessible("Error loading this collection", dispatch);
    });
};

export const updateCollection = (collectionId, data, callback) => dispatch => {
  return api.patch(`collection/${collectionId}`, data)
    .then(data => {
      dispatch({
        type: actionTypes.GET_COLLECTION,
        collection: data,
        error: false,
        msg: null,
        loading: false,
      });
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return dispatch({
        type: actionTypes.GET_COLLECTION,
        error: true,
        msg: "Unable to update this Collection",
        loading: false,
      });
    });
};

export const getSchema = ({ collectionId, wikidataId }) => async (dispatch) => {
  try {
    return await api.get(`collection/${collectionId}/schemas/${wikidataId}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createSPARQLQuery = (projectId, data, callback) => dispatch => {
  return api.post('sparql', {project: projectId, ...data})
    .then(data => {
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return dispatch({
        type: actionTypes.CREATE_SPARQL_QUERY,
        error: true,
        msg: 'Unable to create this query',
        loading: false,
      });
    });
};

export const getSPARQLQuery = (id, callback) => dispatch => {
  return api.get(`sparql/${id}`)
    .then((data) => {
      if (callback) return callback(data);
    })
    .catch((err) => {
      console.error(err);
      return pageNotAccessible('Error loading this preview', dispatch);
    });
};

export const updateSPARQLQuery = (id, data, callback) => dispatch => {
  return api.patch(`sparql/${id}`, data)
    .then(data => {
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return dispatch({
        type: actionTypes.UPDATE_SPARQL_QUERY,
        error: true,
        msg: 'Unable to update this query',
        loading: false,
      });
    });
};

export const updateSchema = (collectionId, data, callback, errCallback) => dispatch => {
  return api.post(`collection/${collectionId}/set_schema`, data)
    .then(data => {
      dispatch({
        type: actionTypes.GET_COLLECTION,
        collection: data,
        error: false,
        msg: null,
        loading: false,
      });
      if (callback) return callback(data);
    })
    .catch(err => {
      const error = api.parseError(err);
      console.error(error);
      if (errCallback) return errCallback(error.data.detail);
      return dispatch({
        type: actionTypes.GET_COLLECTION,
        error: true,
        msg: 'Unable to update this Schema',
        loading: false,
      });
    });
};


export const saveStory = (collectionId, data, callback, errCallback) => dispatch => {
  return api.post(`collection/${collectionId}/save_story`, data)
    .then(data => {
      if (callback) return callback(data);
    })
    .catch(err => {
      const error = api.parseError(err);
      console.error(error);
      if (errCallback) return errCallback(error.data.detail);
    });
};


export const updateCollectionPlan = (collectionId, planId, callback) => dispatch => {
  return api.post(`collection/${collectionId}/plan`, {plan: planId})
    .then(data => {
      dispatch({
        type: actionTypes.GET_COLLECTION,
        collection: data,
        error: false,
        msg: null,
        loading: false,
      });
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return dispatch({
        type: actionTypes.GET_COLLECTION,
        error: true,
        msg: "Unable to update this Collection",
        loading: false,
      });
    });
};

const handleCollectionResponse = async ({ collection, dispatch }) => {
  await dispatch({
    collection,
    error: false,
    loading: false,
    msg: null,
    type: actionTypes.GET_COLLECTION,
  });
  return collection;
};

export const uploadCollectionDataSet = ({ collectionId, data }) => async (dispatch) => {
  const dataset = data.join();
  const collection = await api.post(`collection/${collectionId}/upload`, { dataset });
  return handleCollectionResponse({ collection, dispatch });
};

export const collectionContainsStory = (wikidataId, apiKey, collectionId, options = {}) => (
  api.get(`collection/${collectionId}/contains/${wikidataId}`, {
    params: { api_key: apiKey, ...options },
  })
);

export const deleteStory = ({ collectionId, refresh, wikidataId }) => async (dispatch) => {
  const collection = await api.delete(`collection/${collectionId}/stories/${wikidataId}`, {
    params: { refresh },
  });
  return handleCollectionResponse({ collection, dispatch });
};

// ======================================== MOMENT ======================================== //


export const getMoments = (callback) => dispatch => {
  return api.get('moment')
    .then(data => {
      // dispatch({
      //   type: actionTypes.GET_COLLECTION,
      //   collection: data,
      //   error: false,
      //   msg: null,
      //   loading: false,
      // });
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return pageNotAccessible("Error loading this collection", dispatch);
    });
};


// ======================================== STORY ======================================== //

export const getStory = (wikidataId, apiKey, collectionId, options = {}) => (
  api.get(`story/${wikidataId}`, {
    params: { api_key: apiKey, collection: collectionId, ...options },
  })
);

export const getStoryPreview = (previewCtx, itemId, callback) => dispatch => {
  return api.post(`story/${itemId}/preview`, previewCtx)
    .then(data => {
      // dispatch({
      //   type: actionTypes.GET_COLLECTION,
      //   collection: data,
      //   error: false,
      //   msg: null,
      //   loading: false,
      // });
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return pageNotAccessible("Error loading this preview", dispatch);
    });
};

export const getStoryCount = (collectionId, refresh, callback) => {
  return api.get(`story/count`, {params: {collection: collectionId, refresh}})
    .then(data => {
      if (callback) return callback(data.count);
    })
    .catch(err => {
      console.error(err);
    });
};


// ======================================== BILLING ======================================== //
export const getPlans = (callback) => dispatch => {
  return api.get('billing/plan')
    .then(data => {
      dispatch({
        type: actionTypes.GET_PLANS,
        plans: data,
        loading: false,
      });
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return {
        type: actionTypes.GET_PLANS,
        error: true,
        msg: "Unable to Fetch API Plans",
        loading: false,
      }
    });
};

// ======================================== BLOG ======================================== //
export const getArticles = (projectId, callback, errCallback) => {
  return api.get('article', {params: {project: projectId, page: 0, include_draft: true}})
    .then(data => {
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      errCallback && errCallback(err)
    });
};

// ======================================== LEGAL ======================================== //
export const getSLA = (callback, errCallback) =>  {
  return api.get('legal/sla')
    .then(data => {
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      errCallback && errCallback(err);
    });
};


// ====================================== FEATURE FLAGS ================================= //
export const getPublisherWorkspaceSettings = (callback) => dispatch => {
  return api.get('publisher/settings')
    .then(data => {
      dispatch({
        type: actionTypes.GET_PUBLISHER_WORKSPACE_SETTINGS,
        settings: data,
        loading: false,
      });
      if (callback) return callback(data);
    })
    .catch(err => {
      console.error(err);
      return {
        type: actionTypes.GET_PUBLISHER_WORKSPACE_SETTINGS,
        error: true,
        msg: 'Unable to Fetch API Publisher Settings',
        loading: false,
      }
    });
};
