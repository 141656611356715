import React from 'react';
import { makeStyles, Menu, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2),
  }
}));

export default function NotificationMenu(props) {
  const { anchorEl, onClose, open, notifications } = props;
  const classes = useStyles();
  const menuId = 'primary-search-notification-menu';
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.content}>
        <Typography variant='caption' color="textSecondary">
          {notifications.length ? (
            `You have ${notifications.length} notifications`
          ) : (
            "You currently have no notifications awaiting your attention"
          )}
        </Typography>
      </div>
    </Menu>
  );
}