import axios from "axios";

const storyAPIRequest = axios.create({
  // timeout: 20000,
  headers: { "Content-Type": "application/json" }
});

const GENERAL_ERROR_MESSAGE = "Internal Error. Please try again.";
const RETRY_STATUS_CODE = 206;
const RETRY_WAIT_TIME = 1000;


storyAPIRequest.interceptors.request.use(config => {
    // Parse slug with base url
    if (!config.url.startsWith("http")) {
      config.url = `/api/${config.url}/`;
    }

    const token = localStorage.getItem("token");
    if (token) config.headers.Authorization = "Token " + token;
    console.log("CALLING", config);
    return config;
  },
  error => {
    console.log("req interceptor", error);
    return Promise.reject(error);
});

storyAPIRequest.interceptors.response.use(response => {
  const { config, data, status } = response;
  if (status === RETRY_STATUS_CODE && data && data.retry_url){
    const newConfig = {...config};
    newConfig.url = data.retry_url;
    newConfig.params = {};
    return sleepRequest(RETRY_WAIT_TIME, newConfig);
  }
  return data;
});

storyAPIRequest.parseError = (err) => {
  return err.response || GENERAL_ERROR_MESSAGE;
};

// https://gist.github.com/edmondburnett/38ed3451de659dc43fa3f24befc0073b
const sleepRequest = (milliseconds, originalRequest) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(storyAPIRequest(originalRequest)), milliseconds);
    });
};

export default storyAPIRequest;
