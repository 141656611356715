import React from 'react';
import {Grid, Link, makeStyles, Menu, MenuItem, Typography} from '@material-ui/core';
import UserAvatar from "../../Avatar/User";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  avatar: {
    margin: "0 auto",
  },
}));


export default function UserMenu(props) {
  const { anchorEl, onClose, open, user } = props;
  const classes = useStyles();
  const menuId = 'primary-search-account-menu';
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
    >
      <Grid container className={classes.content} justifyContent="center">
        <Grid item md={7}>
          <UserAvatar user={user} className={classes.avatar}/>
          <Typography variant="subtitle1" color="primary">
            {user.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {user.email}
          </Typography>
        </Grid>
        <Grid item md={5}>
          <MenuItem onClick={onClose}>Profile</MenuItem>
          <MenuItem onClick={onClose}>My account</MenuItem>
          <Link href="/publisher/logout" color="inherit">
            <MenuItem>Logout</MenuItem>
          </Link>
        </Grid>
      </Grid>
    </Menu>
  );
}
