import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import './material-kit-react/src/assets/scss/material-kit-react.scss?v=1.8.0';

import Loader from './Components/Presentational/Loader';
// TODO: TS Support for all pages
// @ts-ignore
const CollectionPage = lazy(() => import('./Components/Container/Page/Collection'));
// @ts-ignore
const DashboardPage = lazy(() => import('./Components/Container/Page/Dashboard'));
const EmbedCollectionPage = lazy(() => import('./Components/Container/Page/EmbedCollection'));
const EmbedStoryPage = lazy(() => import('./Components/Container/Page/EmbedStory'));
// @ts-ignore
const HomePage = lazy(() => import('./Components/Container/Page/Home'));
// @ts-ignore
const LegalPage = lazy(() => import('./Components/Container/Page/Legal'));
// @ts-ignore
const LoginPage = lazy(() => import('./Components/Container/Page/Login'));
// @ts-ignore
const LogoutPage = lazy(() => import('./Components/Container/Page/Logout'));
// @ts-ignore
const ProjectPage = lazy(() => import('./Components/Container/Page/Project'));
const SignUpPage = lazy(() => import('./Components/Container/Page/SignUp'));
// @ts-ignore
const SPARQLQueryPage = lazy(() => import('./Components/Container/Page/SPARQLQuery'));
const StorySandboxPage = lazy(() => import('./Components/Container/Page/StorySandbox'));

const routes = (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route
        component={CollectionPage}
        path="/publisher/collection/:collectionId/:section/:builderType/:wikidataId"
      />
      <Route
        component={CollectionPage}
        path="/publisher/collection/:collectionId/:section/:builderType"
      />
      <Route
        component={CollectionPage}
        path="/publisher/collection/:collectionId/:section"
      />
      <Route
        component={CollectionPage}
        path="/publisher/collection/:collectionId"
      />
      <Route
        component={EmbedStoryPage}
        path="/publisher/collections/:collectionId/embed/stories/:wikidataId"
      />
      <Route
        component={EmbedCollectionPage}
        path="/publisher/collections/:collectionId/embed/stories/"
      />
      <Route
        component={DashboardPage}
        path="/publisher/dashboard"
      />
      <Route
        component={LegalPage}
        path="/publisher/legal"
      />
      <Route
        component={LoginPage}
        path="/publisher/login"
      />
      <Route
        component={LogoutPage}
        path="/publisher/logout"
      />
      <Route
        component={SignUpPage}
        path="/publisher/join"
      />
      <Route
        component={ProjectPage}
        path="/publisher/project/:projectId"
      />
      <Route
        component={StorySandboxPage}
        path="/publisher/sandbox"
      />
      <Route
        component={SPARQLQueryPage}
        path="/publisher/sparql/:sparqlQueryId"
      />
      <Route
        component={HomePage}
        path="/publisher"
      />
    </Switch>
  </Suspense>
);

export default routes;
