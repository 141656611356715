import AppBar from '@material-ui/core/AppBar';
import Badge, { BadgeOrigin } from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {
  AccountCircle,
  Menu as MenuIcon,
  MoreVert,
  Notifications,
} from '@material-ui/icons';
import React, { ReactNode, useState } from 'react';
import { GoBeaker } from 'react-icons/all';
import { connect } from 'react-redux';

// @ts-ignore
import MainDrawer from '../../Container/Drawer/Main';
// @ts-ignore
import UserAvatar from '../Avatar/User';
// @ts-ignore
import NotificationMenu from '../Menu/Notification';
// @ts-ignore
import UserMenu from '../Menu/User';
import useStyles from './useStyles';
import { AppState } from '../../../types';

const menuId = 'primary-search-account-menu';
const mobileMenuId = 'primary-search-account-menu-mobile';
const notificationMenuId = 'primary-search-notification-menu';
const topLeft = {
  vertical: 'top',
  horizontal: 'left',
} as BadgeOrigin;

interface Props {
  authenticated: AppState['authenticated'];
  children: ReactNode;
  notifications?: object[];
  settings: AppState['settings'];
  user: AppState['user'];
  withDrawer: boolean;
}

const Navbar = ({
  authenticated,
  children,
  notifications = [],
  settings,
  user,
  withDrawer,
}: Props) => {
  const notificationCount = notifications.length;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null as Element | null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null as Element | null);
  const [open, setOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenu] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNotificationMenuOpen = Boolean(notificationMenuOpen);
  const handleToggleDrawer = () => setOpen(!open);
  const handleProfileMenuOpen = (event: MouseEvent) => setAnchorEl(event.currentTarget as Element);
  const handleNotificationMenuOpen = () => setNotificationMenu(true);
  const handleNotificationMenuClose = () => setNotificationMenu(false);
  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event: MouseEvent) => (
    setMobileMoreAnchorEl(event.currentTarget as Element)
  );

  const renderMenuItems = authenticated ? [(
    <MenuItem
      key="notifications"
      onClick={handleNotificationMenuOpen}
    >
      <IconButton
        aria-haspopup="true"
        aria-label={`show ${notificationCount} new notification(s)`}
        color="inherit"
      >
        <Badge
          anchorOrigin={topLeft}
          badgeContent={notificationCount}
          color="secondary"
        >
          <Notifications />
        </Badge>
      </IconButton>
      <p>
        Notifications
      </p>
    </MenuItem>
  ), (
    <MenuItem
      key="account"
      onClick={handleProfileMenuOpen as () => void}
    >
      <IconButton
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        aria-label="account of current user"
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <p>
        Profile
      </p>
    </MenuItem>
  ), (
    <Link
      color="inherit"
      href="/publisher/logout"
      key="logout"
    >
      <MenuItem>
        Logout
      </MenuItem>
    </Link>
  )] : [(
    <MenuItem key="login">
      <Button
        color="inherit"
        href="/publisher/login"
      >
        Already a Publisher?
      </Button>
    </MenuItem>
  ), (
    <MenuItem key="join">
      <Button
        color="inherit"
        href="publisher/join"
        variant="outlined"
      >
        Join Today
      </Button>
    </MenuItem>
  )];
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      onClose={handleMobileMenuClose}
      open={isMobileMenuOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      { renderMenuItems }
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <CssBaseline>
        <AppBar
          className={classes.appBar}
          position="fixed"
        >
          <Toolbar>
            { withDrawer && (
              <IconButton
                aria-label="open drawer"
                className={classes.menuButton}
                color="inherit"
                edge="start"
                onClick={handleToggleDrawer}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              className={classes.title}
              noWrap
              variant="h6"
            >
              Stories Service
            </Typography>
            <Typography
              className={classes.subtitle}
              noWrap
              variant="subtitle2"
            >
              <Button
                color="inherit"
                href="/publisher"
              >
                Publisher Workspace
              {settings && settings.environment !== 'production' ? (
                <Chip
                  className={classes.subtitleChip}
                  icon={<GoBeaker />}
                  label={settings.environment}
                  size="small"
                />
              ) : null}
              </Button>
            </Typography>
            <div className={classes.grow} />
            {authenticated ? (
              <div className={classes.sectionDesktop}>
                <IconButton
                  aria-controls={notificationMenuId}
                  aria-haspopup="true"
                  aria-label={`show ${notificationCount} new notification(s)`}
                  color="inherit"
                  onClick={handleNotificationMenuOpen}
                >
                  <Badge
                    anchorOrigin={topLeft}
                    badgeContent={notificationCount}
                    color="secondary"
                  >
                    <Notifications />
                  </Badge>
                </IconButton>
                <IconButton
                  aria-controls={menuId}
                  aria-haspopup="true"
                  aria-label="account of current user"
                  color="inherit"
                  edge="end"
                  onClick={handleProfileMenuOpen as () => void}
                >
                  <UserAvatar user={user} />
                </IconButton>
              </div>
            ) : (
              <div className={classes.sectionDesktop}>
                <Button
                  color="inherit"
                  href="/publisher/login"
                >
                  Already a Publisher?
                </Button>
                <Button
                  color="inherit"
                  href="/publisher/join"
                  variant="outlined"
                >
                  Join Today
                </Button>
              </div>
            )}
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen as () => void}
                color="inherit"
              >
                <MoreVert />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        { renderMobileMenu }
        { authenticated && (
          <UserMenu
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            open={isMenuOpen}
            user={user}
          />
        )}
        { authenticated && (
          <NotificationMenu
            anchorEl={anchorEl}
            onClose={handleNotificationMenuClose}
            open={isNotificationMenuOpen}
            notifications={notifications}
          />
        )}
        { withDrawer && (
          <MainDrawer open={open} />
        )}
        <main className={classes.content}>
          { children }
        </main>
      </CssBaseline>
    </div>
  );
};

Navbar.defaultProps = {
  notifications: [],
};

const mapStateToProps = (state: AppState) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(Navbar);
