import useScrollTrigger from '@material-ui/core/useScrollTrigger/useScrollTrigger';
import React, { ReactNode, ReactElement } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AppState } from '../../../types';
import Navbar from '../../Presentational/Navbar';
// @ts-ignore
import Footer from '../Footer';

const ElevationScroll = ({ children }: { children: ReactElement }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

interface Props {
  authenticated: AppState['authenticated'];
  children?: ReactNode;
  projects: AppState['projects'];
  user: AppState['user'];
}

const NavbarContainer = ({
  authenticated,
  children,
  projects,
  user,
}: Props) => (
  <ElevationScroll>
    <Navbar
      authenticated={authenticated}
      user={user}
      withDrawer={authenticated && !!projects && projects.length > 0}
    >
      {children}
      <Footer />
    </Navbar>
  </ElevationScroll>
);

NavbarContainer.defaultProps = {
  children: null,
};

const mapStateToProps = ({ authenticated, projects, user }: AppState) => ({
  authenticated,
  projects,
  user,
});

export default withRouter(connect(mapStateToProps)(NavbarContainer));
