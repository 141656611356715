import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Drawer, List, ListItem, ListItemIcon, Tooltip, Typography } from '@material-ui/core';
import clsx from "clsx";
import { connect } from "react-redux";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerPaper: {
    // paddingLeft: 7,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
}));


function MainDrawer(props) {
  const classes = useStyles();
  const { currentProject, open, projects } = props;
  const currentProjectId = currentProject ? currentProject.id : null;
  return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }, classes.drawerPaper),
        }}
      >
        <div className={classes.toolbar}>
        </div>
        <List>
          {projects && projects.map(({ id, name }) => (
            <ListItem
              button
              component="a"
              key={id}
              href={`/publisher/project/${id}`}
              selected={currentProjectId === id}
            >
              <ListItemIcon>
                {open ? (
                  <Avatar>{name[0]}</Avatar>
                ) : (
                  <Tooltip title={name}>
                    <Avatar>{name[0]}</Avatar>
                  </Tooltip>
                )}
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
               {name}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Drawer>
  );
}

const mapStateToProps = state => {
  return {
    projects: state.projects,
    currentProject: state.currentProject,
  };
};

export default connect(mapStateToProps, { })(MainDrawer);
