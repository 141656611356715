import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.css';
// @ts-ignore
import * as serviceWorker from './serviceWorker';
// @ts-ignore
import store from './redux/store';
import theme from './theme';
// @ts-ignore
import { getPublisherWorkspaceSettings } from './redux/actions';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  seed: 'stories-service-pw',
});

store.dispatch(getPublisherWorkspaceSettings());

ReactDOM.render(
  (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={createTheme(theme)}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MuiThemeProvider>
      </StylesProvider>
    </Provider>
  ),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
